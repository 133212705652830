<template>
  <div class="row">
    <div class="col-lg-4">
      <div class="col-12">
        <div class="white-box text-end">
          <div class="line-cart">
            <LineChart :chartData="lineData" :options="options" />
          </div>
          <div class="text-area right">
            <h2 class="bold-19 text-gradient mb-2">
              {{ $t("problems.repair") }}
            </h2>
            <h2 class="bold-40 text-gradient">23</h2>
          </div>
        </div>
        <button
          class="btn btn-type-4 w-100 text-center mb-3"
          v-on:click="showAddModal(8)"
        >
          <span class="semibold-14 text-gradient">
            {{ $t("problems.new2") }}
          </span>
        </button>
      </div>
    </div>
    <div class="col-lg-4">
      <div class="col-12">
        <div class="white-box text-end">
          <div class="line-cart">
            <LineChart :chartData="lineData2" :options="options" />
          </div>
          <div class="text-area right">
            <h2 class="bold-19 text-gradient mb-2">
              {{ $t("problems.waste") }}
            </h2>
            <h2 class="bold-40 text-gradient">4</h2>
          </div>
        </div>
        <button
          class="btn btn-type-4 w-100 text-center mb-3"
          v-on:click="showAddModal(7)"
        >
          <span class="semibold-14 text-gradient">
            {{ $t("problems.new2") }}
          </span>
        </button>
      </div>
    </div>
    <div class="col-lg-4">
      <div class="col-12">
        <div class="white-box text-end">
          <div class="line-cart">
            <LineChart :chartData="lineData3" :options="options" />
          </div>
          <div class="text-area right">
            <h2 class="bold-19 text-gradient mb-2">
              {{ $t("problems.deficit") }}
            </h2>
            <h2 class="bold-40 text-gradient">8</h2>
          </div>
        </div>
        <button
          class="btn btn-type-4 w-100 text-center mb-3"
          v-on:click="showAddModal(4)"
        >
          <span class="semibold-14 text-gradient">
            {{ $t("problems.new2") }}
          </span>
        </button>
      </div>
    </div>
    <div class="col-12 mb-3">
      <div class="text-end">
        <a :href="getUrl(true)" class="btn btn-outline-primary btn-sm"
          >Export</a
        >
      </div>
    </div>
    <div class="col-lg-12">
      <div v-for="item in items" :key="item.id" class="white-box p-1">
        <div class="row mt-4 mb-1 p-0 ms-2">
          <h3 class="bold-16 col-2">
            {{ item?.id }} -
            {{ formatDate(item.created_at) }}
          </h3>
          <div class="col-1 text-center">
            <span
              class="badge"
              :class="itemOperationTypes[item.operation_type].color"
            >
              {{ itemOperationTypes[item.operation_type].name }}
            </span>
          </div>
          <span v-if="warehouse === null" class="col-4">{{
            item.warehouse
          }}</span>
          <div class="col-1">
            <div class="d-flex no-wrap dropdown">
              <button
                id="printBtn"
                class="btn btn-transparent dropdown-btn"
                data-bs-toggle="dropdown"
                aria-expanded="true"
                :disabled="loadPrintBtn"
              >
                <span
                  class="rounded-box ms-auto me-auto mb-2 spin-dark"
                  :class="{ 'loading-spin': loadPrintBtn }"
                  ref="btn7"
                  data-bs-placement="top"
                  :title="$t(`problems.print`)"
                >
                  <BaseIcon name="printer" />
                </span>
              </button>
              <div
                class="dropdown-menu"
                aria-labelledby="Printing options"
                data-popper-placement="bottom-start"
              >
                <div class="row">
                  <div class="col-12">
                    <button
                      class="dropdown-item regular-12"
                      v-on:click="downloadProductsLabels(item.id)"
                    >
                      <BaseIcon name="barcode" />
                      {{ $t("problems.productLabels") }}
                    </button>
                    <button
                      class="dropdown-item regular-12"
                      v-on:click="downloadBoxesLabels(item.id)"
                    >
                      <BaseIcon name="box" />
                      {{ $t("problems.boxLabels") }}
                    </button>
                    <button
                      class="dropdown-item regular-12"
                      v-on:click="downloadXlsx(item.id)"
                    >
                      <BaseIcon name="active" />
                      {{ $t("problems.xlsx") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <OneByOneTable
          v-model:rows="rows"
          :showAdd="false"
          v-model:items="item.itemOperations"
          type="products"
          :no-min-height="true"
          check-delete-row="left_incoming"
          v-on:delete="ShowdeleteRowModal"
        />
      </div>
    </div>
    <NewManualIncoming
      v-model:show="addNew"
      v-on:newItem="newItem"
      :type="selectedType"
    />
    <ConfirmModal
      ref="noWarehouseModal"
      :title="$t(`outgoing.noWarehouseSelected`)"
      text="please select a warehouse before adding a new income!"
      :show-ok="false"
      cancel-button="Ok"
    />
    <ConfirmModal
      ref="rowDeleteModal"
      :title="$t(`problems.deleteRow`)"
      :text="`<ul><li>You will remove ${deleteObject?.name} from the problems<b></li><li>You can't undo this action</li></ul><p>Are you sure you want to delete?</p>`"
      :cancel-button="`No, keep ${deleteObject?.name}`"
      :ok-button="`Yes, delete ${deleteObject?.name}`"
      v-on:success="deleteRow"
    />
  </div>
</template>

<script>
import { LineChart } from "vue-chart-3";
import OneByOneTable from "../../components/lists/OneByOneTable";
import NewManualIncoming from "@/components/modals/NewManualIncoming";
import ConfirmModal from "@/components/modals/ConfirmModal";
import { useStore } from "vuex";
import http from "../../modules/http";
import date from "@/modules/date";
import ItemOperationTypes from "../../modules/ItemOperationTypes";
import BaseIcon from "../../components/icons/BaseIcon.vue";
import printJS from "print-js";
import tenant from "../../modules/tenant";

export default {
  name: "Problems",
  components: {
    BaseIcon,
    ConfirmModal,
    NewManualIncoming,
    OneByOneTable,
    LineChart,
  },

  data() {
    let sample_data = {
      Labels: [
        "2021-08-02",
        "2021-08-03",
        "2021-08-04",
        "2021-08-05",
        "2021-08-06",
      ],
      Values: [6, 4, 3, 8, 2],
    };
    let sample_data2 = {
      Labels: [
        "2021-08-02",
        "2021-08-03",
        "2021-08-04",
        "2021-08-05",
        "2021-08-06",
      ],
      Values: [14, 20, 13, 18, 22],
    };
    let sample_data3 = {
      Labels: [
        "2021-08-02",
        "2021-08-03",
        "2021-08-04",
        "2021-08-05",
        "2021-08-06",
      ],
      Values: [3, 4, 3, 0, 2],
    };

    return {
      store: useStore(),
      itemOperationTypes: ItemOperationTypes,
      selectedType: null,
      addNew: false,
      deleteObject: {},
      options: {
        padding: 0,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          x: {
            display: false,
            grid: {
              display: false,
            },
          },
          y: {
            display: false,
            grid: {
              display: false,
            },
          },
        },
      },
      lineData: {
        labels: sample_data.Labels,
        datasets: [
          {
            label: "Current value",
            data: sample_data.Values,
            lineTension: 0.45,
            borderColor: "rgba(160,85,224,1)",
            backgroundColor: "rgba(160,85,224,0.3)",
            fill: true,
          },
        ],
      },
      lineData2: {
        labels: sample_data2.Labels,
        datasets: [
          {
            label: "Current value",
            data: sample_data2.Values,
            lineTension: 0.45,
            borderColor: "rgba(160,85,224,1)",
            backgroundColor: "rgba(160,85,224,0.3)",
            fill: true,
          },
        ],
      },
      lineData3: {
        labels: sample_data3.Labels,
        datasets: [
          {
            label: "Current value",
            data: sample_data3.Values,
            lineTension: 0.45,
            borderColor: "rgba(160,85,224,1)",
            backgroundColor: "rgba(160,85,224,0.3)",
            fill: true,
          },
        ],
      },
      data: {},
      rows: [
        {
          name: "image",
          key: "image",
          type: "image",
          show: true,
          editable: false,
        },
        {
          name: "Serial",
          key: "serial",
          type: "string",
          show: true,
          editable: false,
        },
        {
          name: "Color",
          key: "characteristics_1", //TODO tmp add backend
          type: "string",
          show: true,
          editable: false,
        },
        {
          name: "Size",
          key: "characteristics_2",
          type: "string",
          show: true,
          editable: false,
        },
        {
          name: "Description",
          key: "description",
          type: "string",
          show: true,
          editable: false,
        },
        {
          name: "Name",
          key: "name",
          type: "string",
          show: true,
          editable: false,
        },
        {
          name: "Quantity",
          key: "left_incoming",
          type: "number",
          show: true,
          editable: false,
        },
        {
          name: "Original quantity",
          key: "quantity",
          type: "number",
          show: true,
          editable: false,
        },
        {
          name: "SKU",
          key: "sku",
          type: "string",
          show: true,
          editable: false,
        },
      ],
      filtersTimeOut: null,
      page: 1,
      items: null,
    };
  },
  computed: {
    warehouse() {
      return this.store.state.topBar.warehouse;
    },
    warehouses() {
      return this.store.state.warehouses;
    },
    date() {
      return this.store.state.topBar.date;
    },
    q() {
      return this.store.state.topBar.q;
    },
    mediaServer() {
      return process.env.VUE_APP_MEDIA_URL;
    },
  },
  mounted() {
    this.loadData();
  },
  watch: {
    warehouse() {
      this.loadData();
    },
    q() {
      if (this.filtersTimeOut !== null) {
        clearTimeout(this.filtersTimeOut);
      }
      this.filtersTimeOut = setTimeout(() => {
        this.filtersTimeOut = null;
        this.page = 1;
        this.loadData();
      }, 450);
    },
    date: {
      deep: true,
      handler() {
        this.loadData();
      },
    },
  },
  methods: {
    downloadProductsLabels(id) {
      const _this = this;
      this.loadPrintBtn = true;

      let url = `${tenant.getApiBaseUrl()}/production/${id}/barcode/?_token=${
        this.store.state.token
      }`;

      if (navigator.userAgent.toLowerCase().indexOf("firefox") > -1) {
        window.open(url, "_blank");
        _this.loadPrintBtn = false;
      } else {
        printJS({
          printable: url,
          onLoadingEnd: function () {
            _this.loadPrintBtn = false;
          },
        });
      }
    },
    downloadXlsx(id) {
      window.open(
        `${tenant.getApiBaseUrl()}/warehouses/outgoing/${id}/xlsx?_token=${
          this.store.state.token
        }`,
        "_blank"
      );
    },
    downloadBoxesLabels(id) {
      const _this = this;
      this.loadPrintBtn = true;
      let url = `${tenant.getApiBaseUrl()}/production/${id}/box-label/?_token=${
        this.store.state.token
      }`;
      if (navigator.userAgent.toLowerCase().indexOf("firefox") > -1) {
        window.open(url, "_blank");
        _this.loadPrintBtn = false;
      } else {
        printJS({
          printable: url,
          onLoadingEnd: function () {
            _this.loadPrintBtn = false;
          },
        });
      }
    },
    formatDate(val) {
      return date.format(val, true, true);
    },
    appUrl() {
      return tenant.getApiBaseUrl();
    },
    getUrl(exp = false) {
      return (
        (exp ? this.appUrl() : "") +
        "/warehouses/outgoing/problems" +
        (exp ? "/export/xlsx" : "") +
        "?page=" +
        this.page +
        (exp ? "&_token=" + this.store.state.token : "") +
        (this.warehouse ? "&warehouse=" + this.warehouse : "") +
        (this.q ? "&q=" + this.q : "") +
        (this.date[0]
          ? "&from=" + this.date[0].toISOString().split("T")[0]
          : "") +
        (this.date[1] ? "&to=" + this.date[1].toISOString().split("T")[0] : "")
      );
    },
    loadData() {
      if (this.page === 1) {
        this.items = null;
      }
      http.fetch(this.getUrl()).then((data) => {
        for (const item of data.data) {
          for (const product of item.itemOperations) {
            product.image =
              product.image !== null
                ? product.image.indexOf("http") === 0
                  ? product.image
                  : this.mediaServer + "/" + product.image
                : null;
          }
        }
        this.data = data;
        this.items = data.data;
      });
    },
    showAddModal(type) {
      this.selectedType = type;
      if (this.warehouse === null) {
        this.$refs.noWarehouseModal.showModal();
      } else {
        this.addNew = true;
      }
    },
    ShowdeleteRowModal(e) {
      console.log(e);
      this.deleteObject = e;
      this.$refs.rowDeleteModal.showModal();
    },
    deleteRow() {
      http
        .fetch(
          `/warehouses/income/${this.deleteObject.warehouse_operation_id}/packaging`,
          { item: this.deleteObject.id, quantity: 1 },
          true
        )
        .then((data) => {
          console.log(data);
          this.page = 1;
          this.loadData();
        });
    },
    newItem(data) {
      console.log(data);
      // this.items.unshift(data);
      // this.tableKey++;
    },
  },
};
</script>
